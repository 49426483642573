import Vue from "vue"
import {
    Notification
} from 'element-ui';

export const store = Vue.observable({
    showGoBack: true,
    showFooter: false,
    showBuyCoinsDialog: false,
    showBuyMoreCoinsDialog: false,
    showValidateBuyCoinsDialog: false,
    showLoading: false,
    showPoints: false,
    showLogoutOverlay: false,
    ignoreDefaultError: false,
    phoneNumber: '',
    pinCode: '',
    userPoints: 0,
    userBalance: 0,
    footerItem: 'quiz',
    ti: '',
    serviceLang: "en",
    buyCoinsAmount: 3,
    userToken: "",
    API_URL: 'https://torliga.com:1337/'
})

export const notify = (title, message, type, fixed = false) => {
    Notification({
        title: title,
        message: ['!', '.'].includes(message.substr(message.length - 1)) ? message : message + '!',
        duration: fixed ? 0 : 4500,
        type: type,
        customClass: 'notify-class'
    });
}