<template>
  <section class="sec_content">
    <div class="text">
      <div
        style="
          color: #0b9f0b;
          margin-top: 20px;
          font-weight: 600;
          font-size: 20px;
        "
      >
        WELCOME TO
      </div>
      <img
        src="../assets/ht_logo_transparent.png"
        alt=""
        style="height: 50px; margin-top: 10px; margin-bottom: 10px"
      />
    </div>
    <div class="main-text">
      <div
        style="
          color: #fff;
          margin-top: 25px;
          margin-bottom: 25px;
          font-weight: 600;
          font-size: 25px;
        "
        @click="showDetail('shisha')"
      >
        SHISHA
        <i
          style="font-size: 26px; margin-left: 115px"
          class="el-icon-arrow-right"
        ></i>
      </div>
    </div>
    <div class="main-text">
      <div
        style="
          color: #fff;
          margin-top: 25px;
          margin-bottom: 25px;
          font-weight: 600;
          font-size: 23px;
        "
        @click="showDetail('naturalshisha')"
      >
        NATURAL SHISHA
        <i style="font-size: 26px" class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="main-text">
      <div
        style="
          color: #fff;
          margin-top: 25px;
          margin-bottom: 25px;
          font-weight: 600;
          font-size: 25px;
        "
        @click="showDetail('halftime')"
      >
        RESTAURANTS
        <i
          style="font-size: 26px; margin-left: 20px"
          class="el-icon-arrow-right"
        ></i>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "main",
  methods: {
    showDetail(restid) {
      this.$router.push(restid);
    },
  },
};
</script>
<style>
body {
  background-image: url("../assets/menu-QR-background-1.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.text {
  border: 3px solid #0b9f0b;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 8px;
}
.shisha {
  border: 3px solid #0b9f0b;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 8px;
  background-color: #0b9f0b;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  text-align: left;
  text-indent: 30px;
}
.restaurants {
  border: 3px solid #0b9f0b;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 8px;
  background-color: #0b9f0b;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  text-align: left;
  text-indent: 30px;
}
.main-text {
  border: 3px solid #0b9f0b;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 8px;
  background-color: #0b9f0b;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  text-align: left;
  text-indent: 30px;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.restimage {
  border-radius: 8px;
  height: 150px;
  width: 150px;
  cursor: pointer;
  border: 1px solid black;
}

.restimage_nopointer {
  border-radius: 8px;
  height: 150px;
  width: 150px;
  border: 1px solid black;
}
</style>
