<template>
  <section class="sec_content">
    <el-col style="width: 10%">
      <div @click="goBack" style="float: left">
        <i
          style="font-size: 50px; color: white; margin-left: 30px"
          class="el-icon-back"
        ></i>
      </div>
    </el-col>

    <div class="header-shisha">NATURAL SHISHA</div>

    <!-- Gum and mint -->
    <div class="row">
      <div class="col d-flex justify-content-center mt-3">
        <div class="grid-content ep-bg-purple" />
        <div class="shisha-item">
          <div class="d-flex mb-3">
            <div class="me-auto p-1">Gum and mint</div>
            <div class="p-1">25'000</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Grape and mint -->
    <div class="row">
      <div class="col d-flex justify-content-center mt-3">
        <div class="grid-content ep-bg-purple" />
        <div class="shisha-item">
          <div class="d-flex mb-3">
            <div class="me-auto p-1">Grape and mint</div>
            <div class="p-1">25'000</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 2 apples -->
    <div class="row">
      <div class="col d-flex justify-content-center mt-3">
        <div class="grid-content ep-bg-purple" />
        <div class="shisha-item">
          <div class="d-flex mb-3">
            <div class="me-auto p-1">2 apples</div>
            <div class="p-1">25'000</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Lemon and mint -->
    <div class="row">
      <div class="col d-flex justify-content-center mt-3">
        <div class="grid-content ep-bg-purple" />
        <div class="shisha-item">
          <div class="d-flex mb-3">
            <div class="me-auto p-1">Lemon and mint</div>
            <div class="p-1">25'000</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Halftime 1 -->
    <div class="row">
      <div class="col d-flex justify-content-center mt-3">
        <div class="grid-content ep-bg-purple" />
        <div class="shisha-item">
          <div class="d-flex mb-3">
            <div class="me-auto p-1">Halftime 1</div>
            <div class="p-1">25'000</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Halftime 2 -->
    <div class="row">
      <div class="col d-flex justify-content-center mt-3">
        <div class="grid-content ep-bg-purple" />
        <div class="shisha-item">
          <div class="d-flex mb-3">
            <div class="me-auto p-1">Halftime 2</div>
            <div class="p-1">25'000</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Halftime 3 -->
    <div class="row">
      <div class="col d-flex justify-content-center mt-3">
        <div class="grid-content ep-bg-purple" />
        <div class="shisha-item">
          <div class="d-flex mb-3">
            <div class="me-auto p-1">Halftime 3</div>
            <div class="p-1">25'000</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Halftime 4 -->
    <div class="row">
      <div class="col d-flex justify-content-center mt-3">
        <div class="grid-content ep-bg-purple" />
        <div class="shisha-item">
          <div class="d-flex mb-3">
            <div class="me-auto p-1">Halftime 4</div>
            <div class="p-1 mb-3">25'000</div>
          </div>
        </div>
      </div>
    </div>

    <!-- END -->
  </section>
</template>

<script>
export default {
  name: "naturalshisha",
  data() {
    return {
      isSelectLanguage: true,
      serviceData: {},
    };
  },

  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.isSelectLanguage = true;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background-image: url("../assets/menu-QR-background-2.png") !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.header-shisha {
  color: #0b9f0b;
  margin-top: 40px;
  margin-left: 38%;
  font-weight: 500;
  font-size: 30px;
}
.text-shisha {
  color: #fff;
  margin-top: 80px;
  margin-left: 10%;
  font-weight: 400;
  font-size: 20px;
  text-decoration-color: #0b9f0b;
  text-underline-offset: 12px;
  text-decoration-thickness: 1.5px;
  text-decoration-line: underline;
  text-align: left;
}
.shisha-item {
  width: 100vh;
  line-height: 50px;
  margin-left: 10%;
  margin-right: 10%;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 20px;
  text-decoration-color: #0b9f0b;
  text-underline-offset: 12px;
  text-decoration-thickness: 1.5px;
  text-decoration-line: underline;
}
</style>
