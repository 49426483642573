var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sec_content"},[_c('el-col',{staticStyle:{"width":"10%"}},[_c('div',{staticStyle:{"float":"left"},on:{"click":_vm.goBack}},[_c('i',{staticClass:"el-icon-back",staticStyle:{"font-size":"50px","color":"white","margin-left":"30px"}})])]),_c('div',{staticClass:"header-shisha"},[_vm._v("NATURAL SHISHA")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center mt-3"},[_c('div',{staticClass:"grid-content ep-bg-purple"}),_c('div',{staticClass:"shisha-item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"me-auto p-1"},[_vm._v("Gum and mint")]),_c('div',{staticClass:"p-1"},[_vm._v("25'000")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center mt-3"},[_c('div',{staticClass:"grid-content ep-bg-purple"}),_c('div',{staticClass:"shisha-item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"me-auto p-1"},[_vm._v("Grape and mint")]),_c('div',{staticClass:"p-1"},[_vm._v("25'000")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center mt-3"},[_c('div',{staticClass:"grid-content ep-bg-purple"}),_c('div',{staticClass:"shisha-item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"me-auto p-1"},[_vm._v("2 apples")]),_c('div',{staticClass:"p-1"},[_vm._v("25'000")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center mt-3"},[_c('div',{staticClass:"grid-content ep-bg-purple"}),_c('div',{staticClass:"shisha-item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"me-auto p-1"},[_vm._v("Lemon and mint")]),_c('div',{staticClass:"p-1"},[_vm._v("25'000")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center mt-3"},[_c('div',{staticClass:"grid-content ep-bg-purple"}),_c('div',{staticClass:"shisha-item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"me-auto p-1"},[_vm._v("Halftime 1")]),_c('div',{staticClass:"p-1"},[_vm._v("25'000")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center mt-3"},[_c('div',{staticClass:"grid-content ep-bg-purple"}),_c('div',{staticClass:"shisha-item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"me-auto p-1"},[_vm._v("Halftime 2")]),_c('div',{staticClass:"p-1"},[_vm._v("25'000")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center mt-3"},[_c('div',{staticClass:"grid-content ep-bg-purple"}),_c('div',{staticClass:"shisha-item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"me-auto p-1"},[_vm._v("Halftime 3")]),_c('div',{staticClass:"p-1"},[_vm._v("25'000")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center mt-3"},[_c('div',{staticClass:"grid-content ep-bg-purple"}),_c('div',{staticClass:"shisha-item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"me-auto p-1"},[_vm._v("Halftime 4")]),_c('div',{staticClass:"p-1 mb-3"},[_vm._v("25'000")])])])])])
}]

export { render, staticRenderFns }