import Vue from 'vue'
import Router from 'vue-router'
import Main from './components/Main.vue'
import HalftimeComponent from './components/Halftime.vue'
import Macanolli from "./components/macanolli.vue";
import Naznaz from "./components/naznaz.vue"
import Tacoshepherd from "./components/Tacoshepherd.vue"
import Urban from "./components/Urban.vue"
import Zar from "./components/Zar.vue"
import Asianbistro from "./components/AsianBistro.vue"
import OperationShawrma from "./components/OperationShawrma.vue"
import PointBurger from "./components/PointBurger.vue"
import Dindip from "./components/Dindip.vue"
import Legendshub from "./components/LegendsHub.vue"
import Shisha from './components/Shisha.vue'
import Buscafeerbil from './components/Buscafeerbil.vue'
import Juliusmeinl from './components/Juliusmeinl.vue'
import NaturalShisha from './components/NaturalShisha.vue'
import ChezhyKurdawary from './components/ChezhyKurdawary.vue'
import Elite from './components/elite.vue'

Vue.use(Router)

export default new Router({
    routes: [{
            path: '/',
            name: 'main',
            component: Main,
            meta: {
                public: true
            }
        },
        {
            path: '/juliusmmeinl',
            name: 'juliusmmeinl',
            component: Juliusmeinl,
            meta: {
                public: true
            }
        },
        {
            path: '/shisha',
            name: 'shisha',
            component: Shisha,
            meta: {
                public: true
            }
        },
        {
            path: '/naturalshisha',
            name: 'naturalshisha',
            component: NaturalShisha,
            meta: {
                public: true
            }
        },
        {
            path: '/buscafeerbil',
            name: 'buscafeerbil',
            component: Buscafeerbil,
            meta: {
                public: true
            }
        },

        {
            path: '/halftime',
            name: 'halftime',
            component: HalftimeComponent,
            meta: {
                public: true
            }
        },
        {
            path: "/macanolli",
            name: "macanolli",
            component: Macanolli,
        },
        {
            path: "/naznaz",
            name: "naznaz",
            component: Naznaz,
        },
        {
            path: "/tacoshepherd",
            name: "tacoshepherd",
            component: Tacoshepherd,
        },
        {
            path: "/urban",
            name: "urban",
            component: Urban,
        },
        {
            path: "/zar",
            name: "zar",
            component: Zar,
        },
        {
            path: "/asianbistro",
            name: "asianbistro",
            component: Asianbistro,
        },
        {
            path: "/operationshawrma",
            name: "operationshawrma",
            component: OperationShawrma,
        },
        {
            path: "/pointburger",
            name: "pointburger",
            component: PointBurger,
        },
        {
            path: "/dindip",
            name: "dindip",
            component: Dindip,
        },
        {
            path: "/legendshub",
            name: "legendshub",
            component: Legendshub,
        },
        {
            path: "/chezhykurdawary",
            name: "chezhykurdawary",
            component: ChezhyKurdawary,
        },
        {
            path: "/elite",
            name: "elite",
            component: Elite,
        }
    ],
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({
            behavior: 'smooth'
        });
    }
})
