import Vue from 'vue'
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {
      general: {
        points: 'Points: ',
        coins: 'Coins: ',
        versus: 'vs',
        second_choice_unlocked: '2nd choice unlocked',
        unlock_second_choice: 'Unlock 2nd choice by 2 coins',
        english: 'English',
        arabic: 'Arabic',
        kurdish: 'Kurdish',
        logout: 'Logout',
      },
      halftime: {
        enter_msisdn: 'Enter your MSISDN',
        send_sms: 'Subscribe',
        terms_and_conditions: 'Terms and Conditions',
        privacy_policy: 'Privacy Policy',
        close_modal: "Close",
        welcome_sub_message_1: "Welcome to Torliga! 1 day is free and 300 IQD / Day.",
        welcome_sub_message_2: "To unsubscribe send 0 to 3838 (Free of charge)"
      },
      loginValidation: {
        enter_pin: 'Enter the 4-digit PIN code sent to your mobile number',
        validate: 'Validate',
        resend_pin: 'Click here to resend the PIN Code',
      },
      quiz: {
        title: 'Answer the Questions',
        submit: 'Submit',
        next_question: 'Next Question',
        points_earned: 'Points earned: ',
        pay: 'Get coins to answer'

      },
      playMatch: {
        title: 'Guess the winner',
        submit: 'Submit',
        draw: 'Draw',
        points_earned: 'Points earned: ',
        submit_and_pay: 'Submit and Pay ',
        coins: 'coins',
        add_coins: 'Add more points',
        points_per_coin: '(100 points per coin)',
        edit_prediction: 'Edit Prediction'
      },
      matchResults: {
        title: 'Match Results',
        draw: 'Draw',
        awaiting_results: 'Awaiting results',
        total: 'Total: ',
        points: 'points'
      },
      getCoins: {
        submit: 'Submit',
        select_label: 'Select an option',
        enter_pin: 'Enter the 4-digit PIN code sent to your mobile number',
        select_amount: 'Select an amount of coins and submit your request',
        promo: 'Do you want to get more coins 50% off?',
        recharge: 'Sorry, no enough balance, recharge and try again',
        prices: {
          label_3: 'Get 3 Coins by 300 IQD',
          label_6: 'Get 6 Coins by 600 IQD',
          label_9: 'Get 9 Coins by 900 IQD',
          label_12: 'Get 12 Coins by 1,200 IQD',
          label_48: 'Get 48 Coins by 3,600 IQD',
          label_70: 'Get 70 Coins by 5,000 IQD'
        },
        promoPrices: {
          label_3: 'Get 6 Coins by 300 IQD',
          label_6: 'Get 12 Coins by 600 IQD',
          label_9: 'Get 18 Coins by 900 IQD',
          label_12: 'Get 24 Coins by 1,200 IQD',
        }
      },
      error: {
        error: 'Note',
        connexion_error: 'A connection error occurred',
        auth_error: 'Please sign-in again',
        invalid_phone_number: 'Invalid Phone Number',
        select_quiz_response: 'Select an answer',
        select_play_match: 'You have to predict all matches before submit',
        maximum_quiz_reached: 'Maximum quiz questions was reached',
        invalid_pin_code: 'Invalid PIN code',
        waiting_subscription: 'Waiting for subscription...'
      }
    },
    ar: {
      general: {
        points: 'Points: ',
        coins: 'Coins: ',
        versus: 'vs',
        second_choice_unlocked: 'توفر الخيار الثاني',
        unlock_second_choice: 'افتح الخيار الثاني بـ ٢ كوين',
        english: 'انكليزي',
        arabic: 'عربي',
        kurdish: 'كردي',
        logout: 'تسجيل الخروج'
      },
      halftime: {
        enter_msisdn: 'ادخل رقمك',
        send_sms: 'اشترك',
        terms_and_conditions: 'الاحكام والشروط',
        privacy_policy: 'Privacy Policy',
        close_modal: "اغلق",
        welcome_sub_message_1: "ترحب بك Torliga",
        welcome_sub_message_2: "اليوم الأول مجاني ثم 300 د.ع يومياً. لإلغاء الاشتراك ارسل 0 الى 3838 (مجاناً)"
      },
      loginValidation: {
        enter_pin: ' أدخل الرمز المرسل إلى رقمك',
        validate: 'تحقق',
        resend_pin: 'اضغط هنا لإعادة إرسال الرمز',
      },
      quiz: {
        title: 'اجب على الاسئلة',
        submit: 'تأكيد',
        next_question: 'السؤال التالي',
        points_earned: 'النقاط المكتسبة',
        pay: 'تحتاج الى كوين للاجابة'

      },
      playMatch: {
        title: 'خمن الرابح',
        submit: 'تأكيد',
        draw: 'تعادل',
        points_earned: 'النقاط المكتسبة',
        submit_and_pay: 'تأكيد ودفع',
        edit_prediction: 'Edit Prediction',
        coins: 'كوين',
        add_coins: 'اضف نقاط',
        points_per_coin: '١٠٠ نقطة لكل كوين'
      },
      matchResults: {
        title: 'نتائج المباريات',
        draw: 'تعادل',
        awaiting_results: 'في انتظار النتائج',
        total: 'المجموع',
        points: 'نقطة'
      },
      getCoins: {
        submit: 'تأكيد',
        select_label: 'حدد اختيار',
        enter_pin: 'أدخل الرمز المرسل إلى رقمك',
        select_amount: 'حدد عدد الكوينز وأرسل طلبك',
        promo: 'هل ترغب في الحصول على المزيد من الكوينز بخصم 50٪؟',
        recharge: 'عذراً، ليس لديك رصيد كافٍ',
        prices: {
          label_3: ' ٣ كوين بـ ٣٠٠ د.ع',
          label_6: ' ٦ كوين بـ ٦٠٠ د.ع',
          label_9: '٩ كوين بـ ٩٠٠ د.ع',
          label_12: '١٢ كوين بـ ١،٢٠٠ د.ع',
          label_48: '٤٨ كوين بـ ٣،٦٠٠ د.ع',
          label_70: '٧٠ كوين بـ ٥،٠٠٠ د.ع'
        },
        promoPrices: {
          label_3: ' ٦ كوين بـ ٣٠٠ د.ع',
          label_6: ' كوين بـ ٦٠٠ د.ع ١٢',
          label_9: ' كوين بـ ٩٠٠ د.ع ١٨',
          label_12: 'كوين بـ ١,٢٠٠ د.ع ٢٤',
        }
      },
      error: {
        error: 'ملاحظة',
        connexion_error: 'حدث خطأ في الشبكة',
        auth_error: 'الرجاء تسجيل الدخول مرة أخرى',
        invalid_phone_number: 'رقم الهاتف غير صحيح',
        select_quiz_response: 'اختر إجابة',
        select_play_match: 'عليك أن تتوقع نتائج جميع المباريات قبل الإرسال',
        maximum_quiz_reached: 'تم الوصول إلى الحد الأقصى من أسئلة الاختبار',
        invalid_pin_code: 'الرمز غير صالح',
        waiting_subscription: 'قيد الاشتراك'
      }
    },
    ku: {
      general: {
        points: 'Points: ',
        coins: 'Coins: ',
        versus: 'vs',
        second_choice_unlocked: 'بژاردەی دووەم کرایەوە',
        unlock_second_choice: 'بژاردەی دووەم بکەوە بە ٢ کۆین',
        english: 'ئینگلیزی',
        arabic: 'عەرەبی',
        kurdish: 'کوردی',
        logout: 'هاتنە دەرەوە'
      },
      halftime: {
        enter_msisdn: 'ژمارەکەت بنووسە',
        send_sms: 'بەشداربە',
        terms_and_conditions: 'بەند و مەرجەکان',
        privacy_policy: 'Privacy Policy',
        close_modal: "دایخە",
        welcome_sub_message_1: "بەخێرهاتنتان دەکات Torliga",
        welcome_sub_message_2: "یەکەم ڕۆژ بەخۆڕاییە و دواتر ڕۆژانە 300 د.ع. بۆ ڕاگرتنی بەشداریکردن 0 بنێرە بۆ 3838 (بەخۆڕایی)"
      },
      loginValidation: {
        enter_pin: 'کۆدی ژمارەکەت بنووسە کە نێردرا بۆت',
        validate: 'سەلماندن',
        resend_pin: 'ئێرە دابگرە بۆ دووبارە ناردنەوەی کۆد',
      },
      quiz: {
        title: 'وەلامی پرسیارەکان بدە',
        submit: 'پەسەند بکە',
        next_question: 'پرسیاری داهاتوو',
        points_earned: 'خاڵی بەدەستهێنراو',
        pay: 'کۆین بەدەست بهێنە بۆ وەڵامدانەوە'

      },
      playMatch: {
        title: 'پێشبینی براوە بکە',
        submit: 'پەسەند بکە',
        draw: 'یەکسان',
        points_earned: 'خاڵی بەدەستهێنراو',
        submit_and_pay: 'پەسەند بکە و',
        edit_prediction: 'Edit Prediction',
        coins: ' کۆین بدە',
        add_coins: 'خاڵ زیاد بکە',
        points_per_coin: '١٠٠ خاڵ بۆ هەر کۆین'
      },
      matchResults: {
        title: 'ئەنجامی یارییەکان',
        draw: 'یەکسان',
        awaiting_results: 'لە چاوەڕوانی ئەنجامەکان',
        total: 'کۆی گشتی',
        points: 'خاڵ'
      },
      getCoins: {
        submit: 'پەسەند بکە',
        select_label: 'هەڵبژاردەیێک دیاریبکە',
        enter_pin: ' کۆدەکە بنووسە کە نێردرا بۆ ژمارەکەت',
        select_amount: 'ژمارەیەک لە کۆینەکان دیاریبکە و داواکاریەکەت پێشکەش بکە',
        promo: 'دەتەوێت کۆینی زیاتر بەدەست بهێنیت بە داشکاندنی %50؟',
        recharge: 'ببورە، باڵانسی پێویستت نییە.',
        prices: {
          label_3: '٣ کۆین بە ٣٠٠ د.ع',
          label_6: '٦ کۆین بە ٦٠٠ د.ع',
          label_9: '٩ کۆین بە ٩٠٠ د.ع',
          label_12: '١٢ کۆین بە ١،٢٠٠ د.ع',
          label_48: '٤٨ کۆین بە ٣،٦٠٠ د.ع',
          label_70: '٧٠ کۆین بە ٥،٠٠٠ د.ع'
        },
        promoPrices: {
          label_3: '٦ کۆین بە ٣٠٠ د.ع',
          label_6: '١٢ کۆین بە ٦٠٠ د.ع',
          label_9: '١٨ کۆین بە ٩٠٠ د.ع',
          label_12: '٢٤ کۆین بە ١،٢٠٠ د.ع',
        }
      },
      error: {
        error: 'تێبینی',
        connexion_error: 'هەڵەیەکی پەیوەندی ڕوویدا',
        auth_error: 'تکایە دووبارە بڕۆ ژوورەوە',
        invalid_phone_number: 'ژمارەکە دروست نیە',
        select_quiz_response: 'وەڵامێک دیاریبکە',
        select_play_match: 'پێویستە پێشبینی هەموو یاریەکان بکەیت پێش ناردن',
        maximum_quiz_reached: 'ژمارەی دیاریکراوی پرسیاری کویزت تێپەڕاند',
        invalid_pin_code: 'کۆدەکە دروست نیە',
        waiting_subscription: 'داواکاریەکەت لە جێبەجێکردندایە'
      }
    },
  }
})

export default i18n