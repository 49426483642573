<template>
  <div>
    <div class="container">
      <div>
        <img
          src="../assets/ht_logo_transparent.png"
          alt=""
          style="
            height: 50px;
            margin-top: 6px;
            margin-bottom: 10px;
            cursor: pointer;
          "
          @click="showDetail('/')"
        />
      </div>
      <div class="row">
        <!-- 1 -->
<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/OperationShawrma.png"-->
<!--            alt=""-->
<!--            @click="showDetail('operationshawrma')"-->
<!--          />-->
<!--        </div>-->

        <!-- 2 -->
<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/Urban_s.png"-->
<!--            alt=""-->
<!--            @click="showDetail('urban')"-->
<!--          />-->
<!--        </div>-->

        <!-- 3 -->
<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/JuliusMeinl.png"-->
<!--            alt=""-->
<!--            @click="showDetail('juliusmmeinl')"-->
<!--          />-->
<!--        </div>-->

        <!-- 4 -->
<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/THE_LEGENS_HUB.png"-->
<!--            alt=""-->
<!--            @click="showDetail('legendshub')"-->
<!--          />-->
<!--        </div>-->

        <!-- 5 -->
<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/NAZNAZ.jpeg"-->
<!--            alt=""-->
<!--            @click="showDetail('naznaz')"-->
<!--          />-->
<!--        </div>-->

        <!-- 6 -->
<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/macanolli-2.png"-->
<!--            alt=""-->
<!--            @click="showDetail('macanolli')"-->
<!--          />-->
<!--        </div>-->

        <!-- 7 -->
<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/ASIAN_BISTRO.png"-->
<!--            alt=""-->
<!--            @click="showDetail('asianbistro')"-->
<!--          />-->
<!--        </div>-->

        <!-- 8 -->
        <div class="col">
          <div class="grid-content ep-bg-purple" />

          <img
            class="restimage"
            src="../assets/TACO_SHEPHERO.png"
            alt=""
            @click="showDetail('tacoshepherd')"
          />
        </div>

        <!-- 9 -->
<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/Dindip.png"-->
<!--            alt=""-->
<!--            @click="showDetail('dindip')"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/ZAR.png"-->
<!--            alt=""-->
<!--            @click="showDetail('zar')"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/PointBurger.png"-->
<!--            alt=""-->
<!--            @click="showDetail('pointburger')"-->
<!--          />-->
<!--        </div>-->

        <div class="col">
          <div class="grid-content ep-bg-purple" />
          <img
            class="restimage"
            src="../assets/bus-cafe-erbil.png"
            alt=""
            @click="showDetail('buscafeerbil')"
          />
        </div>

<!--        <div class="col">-->
<!--          <div class="grid-content ep-bg-purple" />-->
<!--          <img-->
<!--            class="restimage"-->
<!--            src="../assets/ChezhyKurdawary.jpg"-->
<!--            alt=""-->
<!--            @click="showDetail('chezhykurdawary')"-->
<!--          />-->


<!--          </div>-->
<!--        </div>-->
        <div class="col">
          <div class="grid-content ep-bg-purple" />
          <img
              class="restimage"
              src="../assets/elite.png"
              alt=""
              @click="showDetail('elite')"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "halftime",
  methods: {
    showDetail(restid) {
      this.$router.push(restid);
    },
  },
};
</script>
<style>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.restimage {
  border-radius: 8px;
  /* border-style: solid; */
  border-color: white;
  border-width: 1.5px;
  height: 150px;
  width: 150px;
  cursor: pointer;
}
.imagetext {
  color: white;
  position: absolute;
  top: 5px;
  left: 5px;
}

.restimage_nopointer {
  border-radius: 8px;
  height: 150px;
  width: 150px;
  border: 1px solid black;
}
</style>
