import { render, staticRenderFns } from "./Urban.vue?vue&type=template&id=22159ab5&scoped=true&"
import script from "./Urban.vue?vue&type=script&lang=js&"
export * from "./Urban.vue?vue&type=script&lang=js&"
import style0 from "./Urban.vue?vue&type=style&index=0&id=22159ab5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22159ab5",
  null
  
)

export default component.exports