<template>
  <section class="sec_content">
    <div v-if="isSelectLanguage">
      <el-col style="width: 10%">
        <div @click="goBack" style="float: left">
          <i
            style="font-size: 50px; color: white; margin-left: 30px"
            class="el-icon-back"
          ></i>
        </div>
      </el-col>
      <div v-if="this.$i18n.locale == 'ku'">
        <img class="imgitem" src="../menus/Zar_Menu_en.png" alt="" />
      </div>
      <div v-if="this.$i18n.locale == 'en'">
        <img class="imgitem" src="../menus/Zar_Menu_en.png" alt="" />
      </div>
      <div v-if="this.$i18n.locale == 'ar'">
        <img class="imgitem" src="../menus/Zar_Menu_en.png" alt="" />
      </div>
    </div>
    <div class="Languages" v-if="!isSelectLanguage">
      <button @click="changeLang('ku')" class="btn-lang">کوردی</button>
      <button @click="changeLang('ar')" class="btn-lang">عربی</button>
      <button @click="changeLang('en')" class="btn-lang">English</button>
    </div>
  </section>
</template>

<script>
export default {
  name: "zar",
  data() {
    return {
      isSelectLanguage: true,
      serviceData: {},
    };
  },

  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.isSelectLanguage = true;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.sec_content {
  width: 100%;
  height: 100vh;
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;

  .imgitem {
    width: 100%;
  }
  .tittle {
    color: white;
    background-color: #ff4e00;
    font-weight: bold;
    font-size: 40px;
  }
  .Languages {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: center;

    button {
      outline: none;
      width: 300px;
      margin-bottom: 1rem;
      height: 45px;
      line-height: 45px;
      border: none;
      border-radius: 3px;
      background-color: #ff4e00;
      color: rgb(27, 17, 17);
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .menuitem {
    height: 80px;
    width: 280px;
    line-height: 80px;
    border-radius: 3px;
    background-color: #ff4e00;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
