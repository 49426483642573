<template>
  <span>
    <div id="app" v-loading.fullscreen.lock="showLoading">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </span>
</template>

<script>
import { store } from "./store";
import router from "./router";
// import HeaderComponent from "./components/HeaderDeleteDelete.vue";
import i18n from "./translation";

export default {
  name: "App",
  // components: {
  //   HeaderComponent,
  // },
  computed: {
    showLoading() {
      return store.showLoading;
    },
    showLogoutOverlay() {
      return store.showLogoutOverlay;
    },
  },
  methods: {
    doLogout() {
      store.showLoading = false;
      localStorage.removeItem("userToken");
      store.userToken = "";
      store.showLogoutOverlay = false;
      if (this.$router.currentRoute.name != "halftime") router.push("/");
    },
    setAppHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--appHeight", `${window.innerHeight}px`);
    },
  },
  mounted() {
    window.addEventListener("resize", this.setAppHeight);
    this.setAppHeight();
    let urlParams = new URLSearchParams(window.location.search);
    let lang = urlParams.get("lang");
    if (lang && ["en", "ar", "ku"].indexOf(lang) > -1) {
      i18n.locale = lang;
    } else if (lang && ["kr"].indexOf(lang) > -1) {
      i18n.locale = "ku";
    }
    store.serviceLang =
      lang && ["en", "ar", "ku", "kr"].indexOf(lang) > -1 ? lang : "en";
    store.userToken = localStorage.getItem("userToken");
    if (
      typeof store.userToken != "undefined" &&
      store.userToken != "" &&
      store.userToken &&
      this.$router.currentRoute.name != "quiz"
    ) {
      //console.log(store.userToken);
      this.$router.push("/quiz");
    }
  },
};
</script>

<style>
:root {
  --appHeight: 100%;
}

html,
body {
  background-color: #ffffff00;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0 auto;
  margin-top: 0;
  width: 100vw;
}

.content {
  position: absolute;
  /* top: 50px;
  bottom: 55px; */
  left: 0px;
  right: 0px;
  overflow: auto;
  padding: 10px 0px;
}
</style>
